import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import Links from "../../../components/Links";

const dPIHome_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Description"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                The Division of Program Integrity (DPI) is designed to oversee critical organizational functions, including the following:

                <ul>
                    <li>Regulation/legislative review.</li>
                    <li>Business information/intelligence gathering, analysis and reporting.</li>
                    <li>Contract monitoring.</li>
                    <li>Risk management.</li>
                    <li>Training support and facilitation.</li>
                    <li>Oversight and monitoring for a statewide network of certified and licensed Substance Use Disorder (SUD) programs.</li>
                </ul>

                Within this division are three branches—the Data Analytics Branch, the Program Support Branch, and the Substance
                Use Disorder Program Licensure Branch—which are described below.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "The Substance Use Disorder (SUD) Program Licensure Branch"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>
                The Substance Use Disorder Program Licensure Branch is a newly created branch that brings together the Department’s Driving
                Under the Influence (DUI) Program and Narcotic Treatment Program (NTP) with the licensure of Alcohol and Other Drug Treatment Entities (AODEs).

                <ul>
                    <li>The DUI Program is responsible for monitoring and regulating the statewide network of DUI programs licensed and certified to provide alcohol and other
                        drug assessments, education, and treatment services to persons convicted of DUI. </li>
                    <li>
                        The NTP Program is responsible for the oversight and regulation of the NTPs across the Commonwealth that are licensed to
                        provide medication-assisted treatment for individuals with an opioid use disorder.
                    </li>
                    <li>
                        The Alcohol and other Drug Entity (AODE) licensure program oversees the outpatient and residential substance use disorder treatment facilities that are licensed, regulated, and inspected annually. The responsibility of AODEs was transferred over to BHDID on April 5, 2023.
                    </li>
                </ul>
            </>
        ]
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <>
                The Data Analytics Branch provides oversight of application development and integration; business informatics;
                facility information system management, and the Electronic Medical Records project.
            </>,
            <>
                The branch also provides technical support to the Department for Behavioral Health, Developmental and Intellectual Disabilities and serves as the point of contact for development of technical solutions and interaction with the Commonwealth Office of Technology.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "Program Support Branch"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            "The Program Support Branch is composed of four primary work units: Contract Monitoring, Education/Event Coordination, Risk Management and Mortality Reviews, and Legislation/Regulations.",
            "Each work unit is led by a Team Leader, and staff work cooperatively with other divisions to ensure delivery of high-quality products, accountability, and transparency that are representative of the department’s commitment to effective delivery of behavioral health, developmental and intellectual disability services to Kentuckians."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: "Contract Monitoring"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 90,
        content: [
            "The Program Support Branch administers the contract monitoring process to assist in assuring delivery of specified goods and services in the manner contractually specified in selected contracts each year.   Reported results provide accountability to DBHDID and their contracted vendors and offer an opportunity for improvement in future policy and resource allocation decisions. Contract monitoring also improves the quality of the contract content by proposing appropriate revisions that are written in more measurable and objective descriptions of specified goods and services."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 100,
        content: "Education/Event Coordination"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 110,
        content: [
            "The Educational and Events Coordination Team coordinates and implements Department wide events and trainings which with continuing education units and are open to the general public. Determinations are issued on Provider Training Curricula for Community Support Associates, Peer Support Specialists and Targeted Case Managers to work in agencies to provide services to customers."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 120,
        content: "Risk Management & Mortality Reviews"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 130,
        content: [
            "The risk management team oversees incidents that occur in facilities involving individuals served. Risk management includes the components of effective abuse protection recommended by the Centers for Medicare & Medicaid Services (CMS); incident investigation; tracking and trending of incidents; incident data analysis; and implementing effective actions to protect from harm those individuals served.",
            "The mortality review team analyzes all deaths that occur both within facilities and within the community (the SCL waiver program, Michelle P Waiver program, and individuals utilizing state general funds). A five-member mortality review committee meeting meets monthly."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 140,
        content: "Open Records & Media Requests"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 150,
        content: [
            <>
                Per KRS 61.870 individuals wishing to inspect a public record may submit an open records request by completing our Open Records Request Form
                <br />
                <br />

                <Links linkTypeID="778" />
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 160,
        content: "Administration Regulation Notices"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 170,
        content: [
            <>
                The legislation/regulations team is responsible for the drafting and amendment of administrative regulations. Additionally, they process open records requests and citizen complaints.
            </>,
            // <>
            //     <b>November 20, 2023</b><br /><br />

            //     The Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID) filed a new regulation with the Legislative Research Commission (LRC) on November 13, 2023.  The regulation is available for viewing online on the <a href='https://apps.legislature.ky.gov/law/kar/titles/908/001/'>LRC website.</a>
            // </>,
            // <>
            //     <a className='quick-links-body' href='https://www.chfs.ky.gov/agencies/dbhdid/Documents/908%20KAR%201_410.pdf'>908 KAR 1:410 Recovery housing</a>
            // </>,
            // <>
            //     <b>Summary:</b> This administrative regulation establishes the requirements and standards for the certification of recovery housing. The regulated entities will be required to meet the criteria established in the statute and submit the required application and supporting documentation required to meet the standards established to be certified as a recovery residence.
            // </>,
            // <>
            //     Material incorporated by reference has been filed.  The MIR establishes the standards by which a recovery house will be certified.  Additionally, the KRH Application, Code of Ethics, and Assurances are all required documents that must be submitted by all entities applying for certification.
            // </>,
            // <>
            //     <Links linkTypeID="779" />
            // </>,
            // <>
            //     Written public comments on the ordinary may be submitted to the <a href='mailto:CHFSregs@ky.gov'>Office of Regulatory Affairs </a>through January 31, 2024. Upon request to the <a href='mailto:CHFSregs@ky.gov'>Office of Regulatory Affairs</a>by January 12, 2024 a public hearing will be held January 22, 2024 at 9 a.m. via Zoom.
            // </>,
            // <>
            //     <b>Aug. 1, 2023</b><br /><br />

            //     <b>908 KAR 2:300E & O Kentucky problem gambling assistance account.</b><br /><br />



            //     <b>Summary:</b> This administrative regulation establishes the requirements and standards for the administration of the Kentucky problem gambling assistance fund. It also establishes the eligible entities and activities that may receive funds from the Kentucky Problem Gambling Assistance account and the process by which funds may be granted as required through 2023 Ky. Acts ch. 147. Additionally, entities must provide quality assurance measures and demonstrate the effectiveness of activities, programs, and services.
            // </>,
            // <>
            //     Material incorporated by reference has been filed.  The emergency version is in effect. The Kentucky Problem Gambling Assistance Application is used by eligible entities to apply for funds from the account. The emergency and ordinary versions are identical.
            // </>,
            // <>
            //     Written public comments on the emergency version may be submitted to the Office of Regulatory Affairs  through September 30, 2023. Upon request to the Office of Regulatory Affairs  by September 18, 2023 a public hearing will be held September 25, 2023 at 9 a.m. via Zoom.
            // </>,
            // <>
            //     Written public comments on the ordinary may be submitted to the <a href='mailto:CHFSregs@ky.gov'>Office of Regulatory Affairs</a> through October 31, 2023. Upon request to the <a href='CHFSregs@ky.gov'>Office of Regulatory Affairs</a> by October 16, 2023 a public hearing will be held October 23, 2023 at 9 a.m. via Zoom.
            // </>,
            // <>
            //     <b>Aug. 4, 2022​</b><br /><br />

            //     The Department for Behavioral Health, Developmental and Intellectual Disabilities filed the following proposed administrative regulation amendments Aug. 4, 2022. View the amendments on the <a href='https://apps.legislature.ky.gov/law/kar/titles/908/'>Legislative Research Commission website.</a>
            // </>,
            // <>
            //     <b>908 KAR 1:374 Licensure of nonhospital-based outpatient alcohol and other drug treatment entities</b><br /><br />

            //     <b>Summary:</b>The regulation amendment establishes standards for nonhospital-based alcohol and other drug treatment entities providing ambulatory withdrawal management, outpatient treatment services, intensive outpatient services, partial hospitalization or office-based opiate treatment services. It further establishes standards for operation of narcotic treatment programs in accordance with KRS 222.231(12) and 42 C.F.R. Part 8 and updates federal standards in compliance with Senate Bill 178 (2022 Regular Session).
            // </>,
            // <>
            //     Written public comments may be submitted to the <a href='mailto:CHFSregs@ky.gov'></a>Office of Regulatory Affairs  through Oct. 31, 2022. Upon request to the <a href='CHFSregs@ky.gov'>Office of Regulatory Affairs</a> by Oct. 17, 2022 a public hearing will be held Oct. 24, 2022, at 9 a.m. via Zoom.
            // </>,
            // <>
            //     <b>Feb. 22, 2022</b><br /><br />

            //     <b>908 KAR 3:010 E and O - Patient's rights</b><br /><br />

            //     <b>Summary: </b>This amended emergency administrative regulation incorporates the designation of an essential personal care visitor, as defined and established in SB 100, for a resident of an assisted-living community, long-term care facility or state-owned or -operated psychiatric hospital. The ordinary amended administrative regulation is identical to the above-​referenced regulation.
            // </>,
            // <>
            //     Written public comments on the emergency version may be submitted to the <a href='mailto:CHFSregs@ky.gov'>Office of Regulatory Affairs</a> through April 30, 2022. Upon request to the <a href='mailto:'>Office of Regulatory Affairs</a> ​by Apr. 21, 2022 a public hearing will be held Apr. 28, 2022, at 9 a.m. via Zoom.
            // </>,
            // <>
            //     Written public comments on the identical ordinary version may be submitted to the <a href='CHFSregs@ky.gov'>Office of Regulatory Affairs</a> through May 31, 2022. Upon request to the <a href='CHFSregs@ky.gov'>Office of Regulatory Affairs</a> by May 16, 2022 a public hearing will be held May 23, 2022, at 9 a.m. via Zoom.
            // </>
        ]
    },

];

export default dPIHome_Content;