//Using the react library
import React from "react";
import data from '../data/data.json'
import { useState } from "react";



function DDIDCountyList() {

    const [selected, setSelected] = useState (null);

    

    
    
    // useEffect(() => {

    //     const fetchLinks = async () => {
    //         const [data, status] = await getRequest(null, "crisisnos");
    //         if (status == 200) {
    //             setContent(data.data)
    //         }
    //         else {
    //             console.log(error)
    //         }
    //     };
    //     fetchLinks();
    // }, []);

    return (

        <>
            <div title="County List" className="col-md-4 col-md-3">          
                    {/* Return List */}
                    <select className="form-select" onChange={(e) => {
                        // console.log(e.target.value);
                        const c = data.ddidCountyListContacts?.find((x) => x.county === e.target.value)
                        setSelected(c) 
                        // console.log(selected)  
                    }}>
                        <option>Select a County</option>
                        {
                            
                            
                             data.ddidCountyListContacts.map((data, index) => {
                                return (
                                    <option key={index} value={data.county} className="form-select"
                                        
                                    >{data.county}</option>
                                )
                            })
                        }
                    </select>
              
            </div>
            {selected ? <>
            <br/>
      
            <b><u>{selected.county}:</u></b><br/><br/>
            Contact Person: {selected.contact}<br/>
            Phone Number: {selected.number}<br/>
            
        
                
            </>
            : null }
        </>

    );
}

export default DDIDCountyList;